import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	pillTitle: {
		id: 'experiment-onboarding-tracker.pill.title',
		defaultMessage: 'Guided setup',
		description:
			'The text is shown as a title for onboarding tracker pill. Optimal characters is 30 or less.',
	},
	popupTitle: {
		id: 'experiment-onboarding-tracker.popup.title',
		defaultMessage: 'Get started in Confluence',
		description:
			'The text is shown as a title for onboarding tracker popup. Optimal characters is 30 or less.',
	},
	popupCloseLabel: {
		id: 'experiment-onboarding-tracker.popup.close',
		defaultMessage: 'Close',
		description:
			'The text is shown as a label to close onboarding tracker popup. Optimal characters is 10 or less.',
	},
	trackerItemCreateSiteTitle: {
		id: 'experiment-onboarding-tracker.item.create-site.title',
		defaultMessage: '<b>Create</b> a Confluence site',
		description:
			'The text is shown as a title for onboarding tracker item to create a confluence site. Optimal characters is 40 or less.',
	},
	trackerItemAddContentTitle: {
		id: 'experiment-onboarding-tracker.item.add-content.title',
		defaultMessage: '<b>Add content</b> to a space',
		description:
			'The text is shown as a title for onboarding tracker item to add content to space. Optimal characters is 40 or less.',
	},
	trackerItemAddContentContent: {
		id: 'experiment-onboarding-tracker.item.add-content.content',
		defaultMessage:
			'Brainstorm, collaborate, and document ideas with pages, posts, and whiteboards.',
		description:
			'The text is shown as a action message to add content on onboarding tracker checklist. Optimal characters is 100 or less.',
	},
	trackerItemAddContentButton: {
		id: 'experiment-onboarding-tracker.item.add-content.button',
		defaultMessage: 'Add content',
		description:
			'The text is shown as a button label for add content on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerItemPublishPageContent: {
		id: 'experiment-onboarding-tracker.item.publish-page.content',
		defaultMessage:
			'Publishing content makes it available for viewing, commenting, and editing by others with permission to do so.',
		description:
			'The text is shown as a action message to publish page on onboarding tracker checklist. Optimal characters is 100 or less.',
	},
	trackerItemPubilshPageButton: {
		id: 'experiment-onboarding-tracker.item.publish-page.button',
		defaultMessage: 'Publish page',
		description:
			'The text is shown as a button label for publish page on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerItemInvitePeopleTitle: {
		id: 'experiment-onboarding-tracker.item.invite-people.title',
		defaultMessage: '<b>Invite</b> people to Confluence',
		description:
			'The text is shown as a title for onboarding tracker item to invite people. Optimal characters is 40 or less.',
	},
	trackerItemInvitePeopleContent: {
		id: 'experiment-onboarding-tracker.item.invite-people.content',
		defaultMessage:
			'Add your teammates to start organizing, creating, and collaborating on content.',
		description:
			'The text is shown as a action message to invite people on onboarding tracker checklist. Optimal characters is 100 or less.',
	},
	trackerItemInvitePeopleButton: {
		id: 'experiment-onboarding-tracker.item.invite-people.button',
		defaultMessage: 'Invite people',
		description:
			'The text is shown as a button label for invite people on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerItemOrganizeSpaceTitle: {
		id: 'experiment-onboarding-tracker.item.organize-space.title',
		defaultMessage: '<b>Organize</b> a space',
		description:
			'The text is shown as a title for onboarding tracker item to organize space. Optimal characters is 40 or less.',
	},
	trackerItemOrganizeSpaceContent: {
		id: 'experiment-onboarding-tracker.item.organize-space.content',
		defaultMessage:
			'We set up a space to get you started. Organize the content in a way that works best for your team.',
		description:
			'The text is shown as a action message to organize space for onboarding tracker checklist. Optimal characters is 100 or less.',
	},
	trackerItemOrganizeSpaceButton: {
		id: 'experiment-onboarding-tracker.item.organize-space.button',
		defaultMessage: 'Organize space',
		description:
			'The text is shown as a button label for organize space in onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerSpotlightPageTreeContentTitle: {
		id: 'experiment-onboarding-tracker.spotlight.pagetree-content.title',
		defaultMessage: 'Organize content',
		description:
			'The text is shown as a heading title in pagetree content spotlight for onboarding tracker. Optimal characters is 30 or less.',
	},
	trackerSpotlightPageTreeContentContent: {
		id: 'experiment-onboarding-tracker.spotlight.pagetree-content.content',
		defaultMessage:
			'Try dragging these pages to reorder them and see how content is organized in your space.',
		description:
			'The text is shown as content body in pagetree content spotlight for onboarding tracker. Optimal characters is 100 or less.',
	},
	trackerSpotlightTopNavSpaceItemTitle: {
		id: 'experiment-onboarding-tracker.spotlight.topnav-spaceitem.title',
		defaultMessage: 'Explore your space',
		description:
			'The text is shown as a heading title in top nav space item spotlight for onboarding tracker. Optimal characters is 30 or less.',
	},
	trackerSpotlightTopNavSpaceItemContent: {
		id: 'experiment-onboarding-tracker.spotlight.tsnav-spaceitem.content',
		defaultMessage: 'Select a space to read, edit and collaborate on content.',
		description:
			'The text is shown as content body in top nav space item spotlight for onboarding tracker. Optimal characters is 100 or less.',
	},
	trackerSpotlightInvitePeopleTitle: {
		id: 'experiment-onboarding-tracker.spotlight.invite-people.title',
		defaultMessage: 'Invite people to Confluence',
		description:
			'The text is shown as a heading title in invite people spotlight for onboarding tracker. Optimal characters is 30 or less.',
	},
	trackerSpotlightInvitePeopleContent: {
		id: 'experiment-onboarding-tracker.spotlight.invite-people.content',
		defaultMessage: 'Add others you collaborate with frequently to get the conversation started.',
		description:
			'The text is shown as content body in invite people spotlight for onboarding tracker. Optimal characters is 100 or less.',
	},
	trackerSpotlightPublishPageTitle: {
		id: 'experiment-onboarding-tracker.spotlight.publish-page.title',
		defaultMessage: 'Publish content',
		description:
			'The text is shown as a heading title in publish page spotlight for onboarding tracker. Optimal characters is 30 or less.',
	},
	trackerSpotlightPublishPageContent: {
		id: 'experiment-onboarding-tracker.spotlight.publish-page.content',
		defaultMessage:
			'Published pages are searchable and visible to others in your organization. Think of publishing as making a page “go live”.',
		description:
			'The text is shown as content body in publish page spotlight for onboarding tracker. Optimal characters is 150 or less.',
	},
	trackerSpotlightPageTreeCreatetContent: {
		id: 'experiment-onboarding-tracker.spotlight.pagetree-create.content',
		defaultMessage: 'Add at least one more piece of content to this space to organize.',
		description:
			'The text is shown as content body in add content spotlight for onboarding tracker. Optimal characters is 100 or less.',
	},
	trackerSpotlightHelpNavContent: {
		id: 'experiment-onboarding-tracker.spotlight.help-nav.content',
		defaultMessage: 'Get back to guided setup any time from the Help menu.',
		description:
			'The text is shown as content body in help nav item spotlight for onboarding tracker. Optimal characters is 150 or less.',
	},
	trackerMenuHelpArticles: {
		id: 'experiment-onboarding-tracker.menu.help-articles',
		defaultMessage: 'Help articles',
		description:
			'The text is shown as a menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuHelpArticlesDescription: {
		id: 'experiment-onboarding-tracker.menu.help-articles.description',
		defaultMessage: 'Technical documentation, how-tos, and user guides',
		description:
			'The text is shown as a sub text under menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuAtlassianUniverisity: {
		id: 'experiment-onboarding-tracker.menu.atlassian-university',
		defaultMessage: 'Atlassian University',
		description:
			'The text is shown as a menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuAtlassianCommunity: {
		id: 'experiment-onboarding-tracker.menu.atlassian-community',
		defaultMessage: 'Atlassian Community',
		description:
			'The text is shown as a menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuAtlassianCommunityDescription: {
		id: 'experiment-onboarding-tracker.menu.atlassian-community.description',
		defaultMessage: 'Tips, tricks, and discussion from other users',
		description:
			'The text is shown as a sub text under menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuAtlassianUniverisityDescription: {
		id: 'experiment-onboarding-tracker.menu.atlassian-university.description',
		defaultMessage: 'Hands-on learning for deeper Confluence knowledge',
		description:
			'The text is shown as a sub text under menu item on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuAtlassianUniverisityIconLabel: {
		id: 'experiment-onboarding-tracker.menu.atlassian-university.label',
		defaultMessage: 'Open Atlassian University in a new window',
		description:
			'The text is shown as a menu item icon label on onboarding tracker checklist. Optimal characters is 50 or less.',
	},
	trackerMenuAtlassianCommunityIconLabel: {
		id: 'experiment-onboarding-tracker.menu.atlassian-community.label',
		defaultMessage: 'Open Atlassian Community in a new window',
		description:
			'The text is shown as a menu item icon label on onboarding tracker checklist. Optimal characters is 50 or less.',
	},
	trackerSubmenuHeader: {
		id: 'experiment-onboarding-tracker.submenu.header',
		defaultMessage: 'MORE RESOURCES',
		description:
			'The text is shown as a submenu header on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerPopupTitleBack: {
		id: 'experiment-onboarding-tracker.popup.title.back',
		defaultMessage: 'Back',
		description:
			'The text is shown as a title for onboarding tracker popup. Optimal characters is 10 or less.',
	},
	trackerMenuCloseButton: {
		id: 'experiment-onboarding-tracker.menu.close-button',
		defaultMessage: 'Exit get started guide',
		description:
			'The text is shown as a close button label on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerMenuMoreOptionsButton: {
		id: 'experiment-onboarding-tracker.menu.moreoptions-button',
		defaultMessage: 'More options',
		description:
			'The text is shown as a more options button label on onboarding tracker checklist. Optimal characters is 30 or less.',
	},
	trackerSuccessfulTitle: {
		id: 'experiment-onboarding-tracker.successful.title',
		defaultMessage: 'Now your Confluence is set up',
		description:
			'The text is shown as successfuil screen title on onboarding tracker. Optimal characters is 50 or less.',
	},
});
