import { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	ONBOARDING_TRACKER_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import {
	ONBOARDING_TRACKER,
	ONBOARDING_TRACKER_PILL,
	ONBOARDING_TRACKER_ACTION,
} from './OnboardingTrackerConstants';

export const useOnboardingTrackerExperienceTracker = () => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const startOnboardingTrackerExperience = useCallback((): void => {
		experienceTracker.start({
			name: ONBOARDING_TRACKER_EXPERIENCE,
		});
	}, [experienceTracker]);

	const failOnboardingTrackerExperience = useCallback(
		(error?: Error): void => {
			experienceTracker.fail({
				name: ONBOARDING_TRACKER_EXPERIENCE,
				error: error || new Error('Experience Tracker: OnboardingTracker Failed'),
			});
		},
		[experienceTracker],
	);

	const finishOnboardingTrackerExperience = useCallback((): void => {
		experienceTracker.succeed({
			name: ONBOARDING_TRACKER_EXPERIENCE,
		});
	}, [experienceTracker]);

	const startOnboardingTrackerSpotlight = useCallback(
		({ name, target }: { name: string; target: string }): void => {
			experienceTracker.start({
				name,
				attributes: {
					target,
				},
			});
		},
		[experienceTracker],
	);

	const failOnboardingTrackerSpotlight = useCallback(
		({ name, target, error }: { name: string; target: string; error?: Error }): void => {
			experienceTracker.stopOnError({
				name,
				error: error || new Error('Experience Tracker: OnboardingTrackerSpotlight Failed'),
				attributes: {
					target,
				},
			});
		},
		[experienceTracker],
	);

	const abortOnboardingTrackerSpotlight = useCallback(
		({ name, target }: { name: string; target: string }): void => {
			experienceTracker.abort({
				name,
				reason: 'close',
				attributes: {
					target,
				},
			});
		},
		[experienceTracker],
	);

	const finishOnboardingTrackerSpotlight = useCallback(
		({ name, target }: { name: string; target: string }): void => {
			experienceTracker.succeed({
				name,
				attributes: {
					target,
				},
			});
		},
		[experienceTracker],
	);

	return {
		experienceTracker,
		startOnboardingTrackerExperience,
		failOnboardingTrackerExperience,
		finishOnboardingTrackerExperience,
		startOnboardingTrackerSpotlight,
		failOnboardingTrackerSpotlight,
		abortOnboardingTrackerSpotlight,
		finishOnboardingTrackerSpotlight,
	};
};

export const useOnboardingTrackerEvents = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireCoreActionCompleted = useCallback(
		(attributes) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'completed',
					actionSubject: ONBOARDING_TRACKER_ACTION,
					source: ONBOARDING_TRACKER,
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const fireTrackerButtonClicked = useCallback(
		(actionSubjectId: string) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId,
					source: ONBOARDING_TRACKER,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const fireTrackerPillClicked = useCallback(
		(attributes) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: ONBOARDING_TRACKER_PILL,
					source: ONBOARDING_TRACKER,
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const fireTrackerScreenEvent = useCallback(
		(name) => {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name,
					source: ONBOARDING_TRACKER,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	return {
		fireCoreActionCompleted,
		fireTrackerPillClicked,
		fireTrackerButtonClicked,
		fireTrackerScreenEvent,
	};
};
