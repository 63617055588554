// Analytics Events Consts
export const ONBOARDING_TRACKER = 'onboardingTracker';
export const ONBOARDING_TRACKER_ACTION = 'onboardingTrackerAction';
export const ONBOARDING_TRACKER_PILL = 'onboardingTrackerPill';
export const ONBOARDING_TRACKER_BACK_BUTTON = 'onboardingTrackerBackButton';
export const ONBOARDING_TRACKER_EXIT_BUTTON = 'onboardingTrackerExitButton';
export const ONBOARDING_TRACKER_MINIMIZE_BUTTON = 'onboardingTrackerMinimizeButton';
export const ONBOARDING_TRACKER_MORE_OPTIONS_BUTTON = 'onboardingTrackerMoreOptionsButton';
export const ONBOARDING_TRACKER_HELP_MENU_GUIDED_SETUP_GUIDE_BUTTON =
	'onboardingTrackerHelpMenuGuidedSetupButton';

export const ONBOARDING_TRACKER_MORE_OPTIONS_ITEM_HELP_ARTICLE =
	'onboardingTrackerMoreOptionsItemHelpArticle';
export const ONBOARDING_TRACKER_MORE_OPTIONS_ITEM_ATLASSIAN_UNIVERSITY =
	'onboardingTrackerMoreOptionsItemAtlassianUniversity';
export const ONBOARDING_TRACKER_MORE_OPTIONS_ITEM_ATLASSIAN_COMMUNITY =
	'onboardingTrackerMoreOptionsItemAtlassianCommunity';

export const TRACKERITEMS_CREATE_SITE = 'create-site';
export const TRACKERITEMS_ADD_CONTENT = 'add-content';
export const TRACKERITEMS_PUBLISH_CONTENT = 'publish-content';
export const TRACKERITEMS_INVITE_PEOPLE = 'invite-people';
export const TRACKERITEMS_ORGANIZE_SPACE = 'organize-space';

export const TRACKERSPOTLIGHT_SPACE_NAV = 'space-nav';
export const TRACKERSPOTLIGHT_PAGETREE = 'pagetree';
export const TRACKERSPOTLIGHT_PAGETREE_CREATE = 'pagetree-create';
export const TRACKERSPOTLIGHT_CREATE_BUTTON = 'create-button';
export const TRACKERSPOTLIGHT_PUBLISH_BUTTON = 'publish-button';
export const TRACKERSPOTLIGHT_INVITE_PEOPLE = 'invite-people';

export const CORE_ACTIONS_CHECKLIST_SCREEN = 'onboardingTrackerCoreActionsChecklist';
export const MORE_RESOURCES_SCREEN = 'onboardingTrackerMoreResources';
export const TRACKER_SUCCESS_SCREEN = 'onboardingTrackerSuccessScreen';
