import type { FC } from 'react';
import React, { useState } from 'react';
import { styled } from '@compiled/react';

import { CreatePageButton } from '@confluence/contextual-create';
import { useSSRPlaceholderReplaceIdProp, LoadableAfterPaint } from '@confluence/loadable';
import { ContextualCreateContentPopoverLoader } from '@confluence/create-blank-fabric-page/entry-points/ContextualCreateContentPopover';
import { OnboaridngTrackerAddContentSpotlight } from '@confluence/experiment-onboarding-tracker/entry-points/spotlights/OnboaridngTrackerAddContentSpotlight';
import { useIsCreationDropdownEnabled } from '@confluence/content-types-utils';

import type { SetSelection } from './SpaceViewsMenu';

const SpaceViewsMenuLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceViewsMenu" */ './SpaceViewsMenu'))
			.SpaceViewsMenu,
});

type PagesItemActionsProps = {
	isLicensed: boolean;
	selection: string;
	setSelection: SetSelection;
	spaceKey: string;
	contentTreeSize?: number;
	onItemClick?: () => void;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
});

export const PagesItemActions: FC<PagesItemActionsProps> = ({
	isLicensed,
	selection,
	setSelection,
	spaceKey,
	contentTreeSize,
	onItemClick,
}) => {
	const canShowFolder = selection === 'TREE';
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const isCreationADropdown = useIsCreationDropdownEnabled();
	const [isContextualCreateFocused, setIsContextualCreateFocused] = useState<boolean>(false);
	const [isContextualCreatePopupOpen, setIsContextualCreatePopupOpen] = useState<boolean>(false);

	return (
		<Wrapper data-vc="pages-item-actions" {...ssrPlaceholderIdProp}>
			{isLicensed ? (
				<SpaceViewsMenuLoader
					selection={selection}
					setSelection={setSelection}
					isWhiteboardBetaEnabled={isCreationADropdown}
				/>
			) : null}
			<OnboaridngTrackerAddContentSpotlight
				targetOnClick={(value: boolean) => {
					isCreationADropdown && setIsContextualCreatePopupOpen(value);
				}}
				contentTreeSize={contentTreeSize}
			>
				{isCreationADropdown ? (
					<ContextualCreateContentPopoverLoader
						isContextualCreateFocused={isContextualCreateFocused}
						setIsContextualCreateFocused={setIsContextualCreateFocused}
						shouldShowPopup={isContextualCreatePopupOpen}
						setShouldShowPopup={setIsContextualCreatePopupOpen}
						spaceKey={spaceKey}
						source="spaceLevelContextualCreate"
						rootCreateButtonStyling
						onItemClick={onItemClick}
						shouldRenderToParent
						hideFolder={!canShowFolder}
					/>
				) : (
					<CreatePageButton spaceKey={spaceKey} />
				)}
			</OnboaridngTrackerAddContentSpotlight>
		</Wrapper>
	);
};
