import type { Action } from 'react-sweet-state';
import { createStore, createHook, createActionsHook } from 'react-sweet-state';

type State = {
	isPageTreeContentSpotlightActive: boolean;
	isTopNavSpaceItemSpotlightActive: boolean;
	isMenuItemInvitePeopleSpotlightActive: boolean;
	isPublishButtonSpotlightActive: boolean;
	isNextPageTreeContentSpotlight: boolean;
	isAppNavHelpSpotlightActive: boolean;
};

const initialState: State = {
	isPageTreeContentSpotlightActive: false,
	isTopNavSpaceItemSpotlightActive: false,
	isMenuItemInvitePeopleSpotlightActive: false,
	isPublishButtonSpotlightActive: false,
	isNextPageTreeContentSpotlight: false,
	isAppNavHelpSpotlightActive: false,
};

const actions = {
	showAppNavHelpSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isAppNavHelpSpotlightActive: true,
			});
		},
	closeAppNavHelpSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isAppNavHelpSpotlightActive: false,
			});
		},
	showMenuItemInvitePeopleSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isMenuItemInvitePeopleSpotlightActive: true,
			});
		},
	closeMenuItemInvitePeopleSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isMenuItemInvitePeopleSpotlightActive: false,
			});
		},
	showPublishButtonSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isPublishButtonSpotlightActive: true,
			});
		},
	closePublishButtonSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isPublishButtonSpotlightActive: false,
			});
		},
	showTopNavSpaceItemSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isTopNavSpaceItemSpotlightActive: true,
			});
		},
	closeTopNavSpaceItemSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isTopNavSpaceItemSpotlightActive: false,
			});
		},
	showPageTreeContentSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isPageTreeContentSpotlightActive: true,
			});
		},
	setIsNextPageTreeContentSpotlight:
		(value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				isNextPageTreeContentSpotlight: value,
			});
		},
	closePageTreeContentSpotlight:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isPageTreeContentSpotlightActive: false,
			});
		},
	closeAllSpotlights:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isPageTreeContentSpotlightActive: false,
				isTopNavSpaceItemSpotlightActive: false,
				isMenuItemInvitePeopleSpotlightActive: false,
				isPublishButtonSpotlightActive: false,
				isAppNavHelpSpotlightActive: false,
			});
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useTrackerSpotlights = createHook(Store);
export const useTrackerSpotlightsActions = createActionsHook(Store);
