import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

export const OnboardingNudge = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-OnboardingNudgeTooltip" */ './OnboardingNudgeTooltip'
			)
		).OnboardingNudge,
	loading: ({ children, renderChildrenWhileLoading }) =>
		renderChildrenWhileLoading ? <>{children}</> : null,
});

export const OnboardingCTANudge = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-OnboardingCTANudge" */ './onboarding/OnboardingCTANudge'
			)
		).OnboardingCTANudge,
	loading: ({ children, renderChildrenWhileLoading }) =>
		renderChildrenWhileLoading ? <>{children}</> : null,
});
