import type { ReactNode } from 'react';
import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { usePageLayoutResize } from '@atlaskit/page-layout';

import { OnboardingNudge } from '@confluence/onboarding-nudge-tooltip';
import { HELP } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import {
	ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
	ExperienceStart,
} from '@confluence/experience-tracker';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';

import { useOnboardingTrackerEligible } from '../hooks/useOnboardingTrackerEligible';
import { i18n } from '../OnboardingTrackerI18n';
import { TRACKERSPOTLIGHT_PAGETREE_CREATE } from '../OnboardingTrackerConstants';
import { useOnboardingTrackerExperienceTracker } from '../OnboardingTrackerEvents';
import { useTrackerSpotlights } from './useTrackerSpotlights';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Padding = styled.div({
	paddingLeft: token('space.100', '8px'),
});

export const OnboaridngTrackerAddContentSpotlight = ({
	targetOnClick,
	contentTreeSize,
	children,
}: {
	targetOnClick: (value: boolean) => void;
	children: ReactNode;
	contentTreeSize?: number;
}) => {
	const { formatMessage } = useIntl();
	const { isOnboardingTrackerExperimentEligible } = useOnboardingTrackerEligible();
	const [shouldShow, setShouldShow] = useState(false);
	const { isLeftSidebarCollapsed, expandLeftSidebar } = usePageLayoutResize();
	const [
		{ isPageTreeContentSpotlightActive, isNextPageTreeContentSpotlight },
		{
			closePageTreeContentSpotlight,
			setIsNextPageTreeContentSpotlight,
			showPageTreeContentSpotlight,
		},
	] = useTrackerSpotlights();
	const {
		finishOnboardingTrackerSpotlight,
		abortOnboardingTrackerSpotlight,
		failOnboardingTrackerSpotlight,
	} = useOnboardingTrackerExperienceTracker();
	/**
	 * contentTreeSize
	 * (1) We need at lease 2 pages in contentTreeSize to organize. If less than 2 pages available, we set shouldShow = true for OnboaridngTrackerAddContentSpotlight
	 * (2) Since there are 2 virtual folders/pages + 1 space overview added by default, we subtract by 3 from total to know number of pages that can be organized
	 */
	useEffect(() => {
		if (isOnboardingTrackerExperimentEligible && contentTreeSize && contentTreeSize < 5) {
			setShouldShow(true);
			if (isNextPageTreeContentSpotlight) {
				if (isLeftSidebarCollapsed) {
					expandLeftSidebar();
					setTimeout(() => {
						void showPageTreeContentSpotlight();
					}, 800);
				} else {
					void showPageTreeContentSpotlight();
				}
				void setIsNextPageTreeContentSpotlight(false);
			}
		}
	}, [
		setShouldShow,
		contentTreeSize,
		expandLeftSidebar,
		isLeftSidebarCollapsed,
		isOnboardingTrackerExperimentEligible,
		isNextPageTreeContentSpotlight,
		setIsNextPageTreeContentSpotlight,
		showPageTreeContentSpotlight,
	]);

	const handleTargetOnClick = useCallback(() => {
		void closePageTreeContentSpotlight();
		targetOnClick(true);
		finishOnboardingTrackerSpotlight({
			name: ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
			target: TRACKERSPOTLIGHT_PAGETREE_CREATE,
		});
	}, [closePageTreeContentSpotlight, targetOnClick, finishOnboardingTrackerSpotlight]);

	const handleOutsideOnClick = useCallback(() => {
		void closePageTreeContentSpotlight();

		abortOnboardingTrackerSpotlight({
			name: ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
			target: TRACKERSPOTLIGHT_PAGETREE_CREATE,
		});
	}, [closePageTreeContentSpotlight, abortOnboardingTrackerSpotlight]);

	const handleSpotlightError = useCallback(
		(error: Error) => {
			failOnboardingTrackerSpotlight({
				name: ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT,
				target: TRACKERSPOTLIGHT_PAGETREE_CREATE,
				error,
			});
		},
		[failOnboardingTrackerSpotlight],
	);

	return shouldShow && isPageTreeContentSpotlightActive ? (
		<TransparentErrorBoundary
			attribution={Attribution.CC_ONBOARDING}
			onError={handleSpotlightError}
		>
			<ExperienceStart
				name={ONBOARDING_TRACKER_ORGANIZE_SPACE_SPOTLIGHT}
				attributes={{ target: TRACKERSPOTLIGHT_PAGETREE_CREATE }}
			/>
			<OnboardingNudge
				hidden={!isPageTreeContentSpotlightActive}
				setHidden={() => null}
				content={formatMessage(i18n.trackerSpotlightPageTreeCreatetContent)}
				actionSubjectId={HELP}
				source="onboardingTracker"
				wrapper={Padding}
				width={275}
				position="right-start"
				onClick={handleTargetOnClick}
				onOutsideClick={handleOutsideOnClick}
				onShow={() => targetOnClick(false)}
			>
				{children}
			</OnboardingNudge>
		</TransparentErrorBoundary>
	) : (
		<>{children}</>
	);
};
